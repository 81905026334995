<template>
  <div class="flip-box" @click="flipCard()" :class="{ flipped: flipped }">
    <div class="front" :style="'background-image: url(' + data.image + ')'">
      <div class="content text-center">
        {{ data.title }}
      </div>
    </div>
    <div class="back" @click="goTo(data.url)">
      <div class="content">
        {{ data.text }}
        <div class="read_more" :href="data.url" target="_blank">
          {{ linkText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    data: Object,
    linkText: String,
  },
  data: function() {
    return {
      flipped: false,
    };
  },
  methods: {
    flipCard() {
      this.flipped = !this.flipped;
    },
    goTo(url) {
      var pat = /^https?:\/\//i;
      if (pat.test(url)) {
        window.location = url;
      } else {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bgfade {
  0% {
    background: #efefef;
  }
  100% {
    opacity: #fff;
  }
}
</style>
