<template>
  <div v-resize="onResize" v-if="items.length">
    <div class="cards_container" v-if="items">

      <div class="nav back"  @click="previous()">
    
  <div class="icon"></div>
</div>

      <div class="cards_row flip-boxes">
        <card
          v-for="(item, index) in items.slice(
            current_index,
            current_index + card_count
          )"
          :data="item"
          :linkText="view.config.button || 'Find out more'"
          :key="index + Math.random()"
        />
      </div>

<div class="nav next" @click="next()">
  <div class="icon"></div>
</div>

    </div>
  </div>
</template>

<script>
import card from "./card.vue";

export default {
  name: "cards",
  data() {
    return {
      remain: null,
      topic_text: null,
      card_count: 4,
      current_index: 0,
      viewport: null,
    };
  },
  props: ["view", "items"],
  components: {
    card,
  },
  methods: {
    getData(id) {
      var self = this;
      fetch("https://edgeryders.eu/raw/" + id + ".json")
        .then((response) => {
          response.text().then(function(text) {
            self.items = self.parseJson(text).data;
          });
        })
        .catch((error) => console.error(error));
    },
    parseJson(value) {
      var code = value.match(/```([^`]*)```/);
      if (code) {
        return JSON.parse("{ " + code[1] + " }");
      } else {
        return false;
      }
    },
    onResize({ width }) {
      if (width < 500) {
        this.viewport = "mobile";
        this.card_count = this.items.length;
        this.parallax = false;
      }
    },
    next() {
      if (this.current_index + this.card_count < this.items.length) {
        this.current_index = this.current_index + this.card_count;
      } else {
        this.current_index = 0;
      }
    },
    previous() {
      if (this.current_index - this.card_count < 0) {
        this.current_index = this.items.length - this.card_count;
      } else {
        this.current_index = this.current_index - this.card_count;
      }
    },
  },
  mounted() {
    var width = window.innerWidth;
    if (width < 600) {
      this.viewport = "mobile";
      this.card_count = 100;
      this.parallax = "false";
    }
    if (this.view.config.data) {
      this.getData(this.view.config.data);
    }
  },
};
</script>

<style lang="scss">
.cards_container {
  @apply relative w-full flex items-center;

  &:hover {
    .nav {
      opacity: 1;
       
    }
  }
}

.nav {
  width: 60px;
  height: 250px;
  flex-shrink: 0;
  @apply  absolute flex items-center justify-center;
  transition: all 0.4s ease;
  .icon {
    width: 50px;
    height: 50px;
    background-size: 22px !important;
    @apply rounded-full;
    transition: all 0.3s;
  }
  &:hover {
    cursor: pointer;

    .icon {
      background-color: #efefef !important;
    }

  }
  &.back {
left: -60px;
    .icon {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.4 0a5.6 5.6 0 014 9.5l-37.9 38 37.9 37.9a5.6 5.6 0 010 7.9 5.6 5.6 0 01-7.9 0L1.6 51.5a5.5 5.5 0 01-1.6-4 6 6 0 011.6-4L43.5 1.6A5.4 5.4 0 0147.4 0z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E%0A")
        no-repeat 60% 52%;
    }
  }
  &.next {
right: -60px;
    .icon {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M47.4 0a5.6 5.6 0 014 9.5l-37.9 38 37.9 37.9a5.6 5.6 0 010 7.9 5.6 5.6 0 01-7.9 0L1.6 51.5a5.5 5.5 0 01-1.6-4 6 6 0 011.6-4L43.5 1.6A5.4 5.4 0 0147.4 0z' fill='%23000' fill-rule='nonzero'/%3E%3C/svg%3E%0A")
        no-repeat 65% center;
      transform: scale(-1);
    }
  }
}

.cards {
  width: auto;
  @apply mb-6 mx-auto;
}

.cards_row {
  flex-grow: 1;
  @apply relative mt-4 w-full;
  z-index: 2;
  display: flex;
  justify-content: start;
  .flip-box {
    display: flex;
    align-content: stretch;
    height: 380px;
    border-radius: 4px;
    @apply mr-4;
    width: 270px;
    flex-wrap: wrap;
    position: relative;
    padding: 0px;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    &:last-child{
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      .back {
        transform: rotateY(0deg);
        z-index: 10;
      }

      .front {
        transform: rotateY(180deg);
        z-index: -1;
      }
    }

    .back,
    .front {
      position: relative;
      border-radius: 4px;
      background-color: #1b2d61;
      color: white;
      -webkit-backface-visibility: hidden;
      border: 1px solid #ddd;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-content: center;
      flex: 0 0 100%;
      -webkit-transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
      transition: all 1s cubic-bezier(0.5, 1.3, 0.5, 1.3);
      transform-style: preserve-3d;
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
    }

    .back {
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      transform: rotateY(-180deg);
      .content {
        color: black;
        @apply text-center;
        padding: 14px;
        font-size: 14px;
        display: flex;
        width: 95%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .read_more {
          @apply font-bold mt-2 underline;
        }
      }
    }

    .front {
      z-index: 10;
      @apply relative h-full;
      .content {
        @apply text-lg px-6 py-3 pb-7 w-full font-bold absolute;
        top: 0% !important;
        height: 100%;
        z-index: 9;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "";
          @apply absolute;
          bottom: 0;
          left: 0;
          z-index: -1;
          height: 120%;
          width: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
    }

    .content {
      -webkit-transform: translateZ(50px);
      transform: translateZ(50px);
    }
  }
}

@media only screen and (max-width: 600px) {
  .cards_container {
    margin-left: 0%;
  }
  .cards_row {
    justify-content: start;
    width: 100%;

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    display: flex;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    .flip-box {
      scroll-snap-align: center;
      height: 400px;
      flex-shrink: 0 !important;
      flex: auto;
      &:hover {
        .back {
          transform: rotateY(-180deg);
          z-index: -1;
        }

        .front {
          transform: rotateY(0deg);
          z-index: 10;
        }
      }
      &.flipped {
        .back {
          transform: rotateY(0deg);
          z-index: 10;
        }

        .front {
          transform: rotateY(180deg);
          z-index: -1;
        }
      }
    }
  }
  .nav {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .cards {
    width: 100%;
    margin: 0 auto;
    .cards_container {
      width: auto;
      margin: 0 auto;
      padding: 0.4rem 0 1rem;
      overflow: hidden;
      width: 95vw;
      .cards_row {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        display: flex;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        .flip-box {
          scroll-snap-align: start;
          width: 300px !important;
          height: 400px;
          flex-shrink: 0 !important;
          flex: auto;
        }
      }
      .item {
        width: 400px !important;
      }
    }
  }
}
</style>
