<template>
  <div class="preview">
    <div class="tabs flex">
      <div @click="active = 'code'" :class="{ active: active == 'code' }">
        code
      </div>
      <div @click="active = 'visual'" :class="{ active: active == 'visual' }">
        preview
      </div>
    </div>

    <div class="code" v-if="active == 'code'" v-html="config.content"></div>

    <div class="visual" v-if="active == 'visual'">
      <div
        class="preview_header md"
        v-if="config.component == 'header' && config.lang == 'md'"
        style="background-image: url(https://source.unsplash.com/random);"
      >
        <div class="preview_header_title">
          <h1>My title.</h1>
          <p>Some paragraph text as subtitle.</p>
          <a class="button">My button 1</a><a class="button">My button 2</a>
        </div>
      </div>

      <div
        class="preview_header xml"
        v-if="config.component == 'header' && config.lang == 'xml'"
        style="background: url('https://images.unsplash.com/photo-1604079628040-94301bb21b91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80');"
      >
        <div class="preview_header_title">
          <h1>My title.</h1>
          <p>Some paragraph text as a subtitle.</p>
          <a class="button">My Button #1</a><a class="button">My Button #2</a>
        </div>
        <div class="image">
          <img
            src="https://edgeryders.eu/uploads/default/original/2X/1/16471b8b904274ca9aa14e55b30801f2d17b5ac3.png"
          />
        </div>
      </div>

      <div
        class="preview_title"
        v-if="config.component == 'title'"
        v-html="mdRender(config.text)"
      ></div>

      <div
        class="preview_text"
        v-if="config.component == 'text'"
        v-html="config.text"
      ></div>

      <div class="preview_view" v-if="config.component == 'view'">
        <div class="view" :style="view.config.style">
          <div
            v-for="(content, index) in view.content"
            v-html="content"
            :key="index"
          ></div>
        </div>
      </div>

      <div class="preview_grid" v-if="config.component == 'grid'">
        <masonry :cols="3" :gutter="20" v-if="json">
          <div
            class="box"
            v-for="(item, index) in json.slice(0, comp_config.items)"
            :key="index"
            @click="openLink(item.url)"
            :class="{ link: item.url }"
          >
            <div v-if="item.type && item.type == 'post'" class="post">
              <h2>{{ item.title }}</h2>

              <div class="post_stats pb-3">
                <div class="date mr-6">{{ item.date | dateShort }} ·</div>
                <div class="user_badge" v-if="item.user">
                  <img
                    :src="
                      'https://edgeryders.eu' +
                        item.user.avatar_template.replace('{size}', '200')
                    "
                  />
                  <span class="font-bold">@{{ item.user.username }}</span>
                </div>
              </div>

              <img v-if="item.image" :src="item.image" />
              <p>{{ item.text.substring(0, 280) }}..</p>
            </div>

            <div v-else>
              <div
                v-for="(value, key, index) in item"
                :key="index"
                :class="key"
              >
                <h2 v-if="key == 'title'" v-html="value"></h2>
                <p v-if="key == 'text'" v-html="value"></p>
                <p v-if="key == 'subtitle'" v-html="value"></p>
                <img v-if="key == 'image'" :src="value" />
              </div>
            </div>
          </div>
        </masonry>

        <masonry :cols="3" :gutter="20" v-else>
          <div
            class="box"
            v-for="(item, index) in view"
            :key="index"
            v-html="mdRender(item)"
          ></div>
        </masonry>
      </div>

      <masonry
        :cols="3"
        v-if="config.component == 'gallery'"
        class="gallery preview_gallery"
        :gutter="20"
      >
        <img
          v-preview:scope-a
          v-for="(item, index) in json"
          :src="item.image"
          :alt="item.text"
          :key="index"
        />
      </masonry>

      <div class="preview_slider" v-if="config.component == 'slider'">
        <swiper class="slider" :options="sliderOptions" ref="pages">
          <swiper-slide
            v-for="(item, index) in json"
            class="slide"
            :class="[item.type, { image: item.image }, { link: item.url }]"
            :key="index"
            :style="'background: url(' + item.image + ') #fff'"
            @click.native="openLink(item.url)"
          >
            <div
              class="slide_image"
              v-if="item.type == 'post' && item.image"
              :style="'background: url(' + item.image + ')'"
            ></div>
            <div class="slide_text">
              <h3>{{ item.title }}</h3>
              <div class="post_stats">
                <div v-if="item.date" class="post_date">
                  {{ item.date | dateShort }} ·
                </div>
                <div class="user_badge" v-if="item.user">
                  <img
                    :src="
                      'https://edgeryders.eu' +
                        item.user.avatar_template.replace('{size}', '200')
                    "
                  />
                  <span class="font-bold">@{{ item.user.username }}</span>
                </div>
              </div>
              <p class="post_text" v-if="item.type == 'post'">
                {{ item.text.substring(0, 330) }}
              </p>
              <p v-else>{{ item.text }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="preview_table" v-if="config.component == 'table'">
          <list :view="view" />
      </div>
    </div>
  </div>
</template>

<script>
import createPreviewDirective from "vue-photoswipe-directive";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI from "photoswipe/dist/photoswipe-ui-default";
import { directive } from "vue-awesome-swiper";

import list from "@/elements/list.vue";

// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";

export default {
  name: "preview",
  props: ["config"],
  components: {
    list
  },
  data() {
    return {
      active: "code",
      view: null,
      json: null,
      gallery: null,
      comp_config: {
        items: 100,
      },
      sliderOptions: {
        slidesPerView: 1.2,
        autoHeight: true,
        speed: 600,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          duration: 500,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  directives: {
    preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI),
    swiper: directive,
  },
  methods: {
    createView(text) {
      var doc = document.createElement("div");
      doc.innerHTML = text;
      var element = doc.querySelector("view");
      var config = this.getAttributes(element);
      var content = element.innerHTML.split("--");
      var obj = {
        config: config,
        type: "view",
        content: content,
      };

      return obj;
    },
    openLink(url) {
      console.log(url);
      window.open(url);
    },
    cleanText(html) {
      var doc = document.createElement("div");
      doc.innerHTML = html;
      return doc.innerText;
    },
    createPost(item, users) {
      var user = users.filter((x) => x.id == item.posters[0]["user_id"])[0];
      var obj = {
        type: "post",
        title: item.title,
        date: item.created_at,
        user: user,
        text: this.cleanText(item.excerpt),
        likes: item.likes,
        id: item.id,
        image: item.image_url,
        url: "https://edgeryders.eu/t/" + item.id,
        comments: item.posts_count,
      };
      return obj;
    },
    createSlide(text) {
      var html = this.mdRender(text);
      console.log(html);
      var doc = document.createElement("div");
      doc.innerHTML = html;

      var img = doc.querySelector("img").getAttribute("src");
      var url = null;
      var title = null;
      if (doc.querySelector("h2") !== null) {
        title = doc.querySelector("h2").innerText;
        doc.querySelector("h2").remove();
      }
      if (doc.querySelector("a") !== null) {
        url = doc.querySelector("a").getAttribute("href");
        title = doc.querySelector("a").innerText;
        doc.querySelector("a").remove();
      }
      var extract = doc.innerText.replace("\n", "");
      console.log(extract);
      var obj = {
        image: img,
        url: url,
        title: title,
        text: extract,
      };
      console.log(obj);
      return obj;
    },
  },
  created() {
    if (this.config.component == "view") {
      this.view = this.createView(this.config.text);
    }
    var doc = document.createElement("div");
    var self = this;

    if (this.config.component == "slider") {
      this.json = "hello";
      doc.innerHTML = this.markdownRender(this.config.text);
      var slider = doc.querySelector("slider");
      if (slider.getAttribute("tag")) {
        fetch(
          "https://edgeryders.eu/tag/" + slider.getAttribute("tag") + ".json"
        )
          .then((response) => response.json())
          .then((data) => {
            self.json = data.topic_list.topics.map((x) =>
              self.createPost(x, data.users)
            );
          })
          .catch((error) => console.error(error));
      } else {
        var array = slider.innerHTML.split("--");
        this.json = array.map((x) => this.createSlide(x));
      }
    }

    if (this.config.component == "gallery") {
      doc.innerHTML = this.markdownRender(this.config.text);
      var gallery = doc.querySelector("gallery");

      if (gallery.getAttribute("data")) {
        fetch("https://edgeryders.eu/raw/" + gallery.getAttribute("data"))
          .then((response) => {
            response.text().then(function(text) {
              self.json = self.parseJson(text);
            });
          })
          .catch((error) => console.error(error));
      } else {
        this.json = this.createGallery(doc).content;
      }
    }
    if (this.config.component == "grid") {
      doc.innerHTML = this.config.text;
      var element = doc.querySelector("grid");
      if (element.getAttribute("display")) {
        this.comp_config.items = element.getAttribute("display");
      }
      if (element.getAttribute("data")) {
        fetch("https://edgeryders.eu/raw/" + element.getAttribute("data"))
          .then((response) => {
            response.text().then(function(text) {
              var array = [];
              array.push(text);
              self.json = self.parseTable(text);
            });
          })
          .catch((error) => console.error(error));
      } else if (element.getAttribute("tag")) {
        fetch(
          "https://edgeryders.eu/tag/" + element.getAttribute("tag") + ".json"
        )
          .then((response) => response.json())
          .then((data) => {
            self.json = data.topic_list.topics.map((x) =>
              self.createPost(x, data.users)
            );
          })
          .catch((error) => console.error(error));
      } else {
        this.view = element.innerHTML.split("--");
      }
    }
    if (this.config.component == "table") {
      doc.innerHTML = this.markdownRender(this.config.text);
      var table = doc.querySelector("table");

      console.log(table.getAttribute("data"))

     var obj = {
          config: {
            data: table.getAttribute("data")
          }
        }
        self.view = obj;
    }
  },
};
</script>
<style lang="scss" scoped>
.preview {
  background: #fafafa;
  @apply border rounded-lg mb-6;
  overflow: hidden;
  .tabs {
    @apply w-full border-b;
    div {
      @apply p-3 px-4 border-r font-bold;
      &:hover {
        cursor: pointer;
      }
      &.active {
        background: #efefef;
      }
    }
  }
  .code {
    @apply pl-6 pb-6 w-full;

    /deep/ pre {
      width: 100%;
      overflow: scroll;

      code {
        white-space: pre !important;
        width: 100%;
      }
    }
  }
}

.preview_header {
  &.xml {
    @apply flex-row;
    .image {
      width: 40%;
      @apply p-2;
    }
    .preview_header_title {
      width: 50%;
      h1 {
        font-family: "Homemade Apple" !important;
        color: white;
        text-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
        @apply text-4xl border-b border-black pb-6 pt-4;
        background: url("https://edgeryders.eu/uploads/default/original/2X/b/b19bb37119d78e88548948a037ab3fbdfad05ab6.png")
          no-repeat center 100%;
        background-size: 190px;
        border: none;
      }
      p {
        font-family: "Playfair Display";
        color: black;
      }
      a.button {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 0;
        color: black;
      }
    }
  }
  &.md {
    &:before {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 200%
      );
      content: "";
      height: 200px;
      width: 100%;
      @apply absolute;
      top: 0;
    }
    &:after {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      content: "";
      height: 300px;
      width: 100%;
      @apply absolute;
      bottom: 0;
    }
  }
  width: 100%;
  height: 300px;
  background-size: cover !important;
  @apply flex items-center  flex-col justify-center relative;
  overflow: hidden;
  .preview_header_title {
    @apply relative text-center;
    z-index: 9;

    h1 {
      @apply text-4xl border-b border-black pb-3;
    }
    p {
      @apply text-2xl;
    }
    a.button {
      background: white;
      @apply inline-block px-4 mt-2 py-3 rounded-full mr-2;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.preview_title,
.preview_text {
  @apply px-6 pt-6 pb-4;
  /deep/ h2 {
    @apply text-3xl border-b pb-2;
  }
  /deep/ h3 {
    @apply text-xl;
  }
}

.preview_view {
  .view {
    @apply w-full p-10 flex;
    /deep/ h3 {
      @apply text-3xl;
    }
    /deep/ h4 {
      @apply text-2xl mb-4;
    }
    /deep/ p {
      @apply text-lg;
    }
    div {
      flex: 1;
      @apply mr-10;
      &:last-child {
        margin: 0;
      }
      /deep/ img {
        width: 100%;
      }
    }
  }
}

.post_stats {
  @apply inline-flex mb-0;
  .post_date {
    @apply text-lg font-bold mr-2;
    color: rgba(0, 0, 0, 0.8);
  }
  .user_badge {
    @apply flex justify-center items-center p-1 py-0;
    padding: 0 !important;
    /deep/ img {
      @apply rounded-full mr-1 my-0;
      width: 25px !important;
      height: 25px;
      margin-bottom: 0rem !important;
    }
    margin-left: 0rem !important;
  }
}
.preview_grid {
  @apply p-8;
  .box {
    @apply border mb-5 text-center rounded flex flex-col overflow-hidden pt-4 px-4 justify-center items-center;
    background: white;
    &.link {
      &:hover {
        cursor: pointer;
        /deep/ h2 {
          text-decoration: underline !important;
        }
      }
    }

    .image {
      margin-bottom: 0 !important;
      /deep/ img {
        margin-top: 0rem !important;
        margin-bottom: 0 !important;
      }
    }
    .title {
      width: 100%;
      @aplpy flex justify-center items-center;
    }
    .date {
      @apply font-bold;
      color: rgba(0, 0, 0, 0.6);
    }

    /deep/ p {
      margin: 0rem 0 1rem;
      @apply text-base;
      img {
        width: calc(100% + 2rem) !important;
        margin-left: -1rem;

        max-width: none;
      }
    }

    /deep/ h2 {
      margin-bottom: 1rem !important;
      width: 100%;
      @apply leading-tight;
    }
    /deep/ img {
      @apply mb-4;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      margin-top: -1rem;
      max-width: none;
      &.emoji {
        @apply mb-2 mt-2 ml-0;
      }
    }
    /deep/ a {
      display: inline-block;
      @apply text-center border-b text-xl mt-0 pb-3;
      margin-bottom: 1rem !important;

      text-decoration: none;
      width: 100%;
      &:hover {
        @apply underline;
      }
    }

    div:last-child {
      img {
        margin-top: 1rem;
      }
    }
    div.post {
      /deep/ img {
        margin-top: 0 !important;
      }
    }
  }
}

.preview_gallery {
  width: 95%;
  margin-left: auto !important;
  left: -10px;
  @apply mx-auto;
}

.preview_slider {
  @apply p-6;
}

.slider {
  min-height: 400px;
  @apply rounded relative block;
  .slide {
    background-size: cover !important;
    overflow: hidden;
    @apply rounded-lg relative p-8 flex flex-col items-start justify-center;
    color: white;
    height: 300px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
    .slide_image {
      width: 330px;
      height: 100%;
      flex-shrink: 0;
      background-size: cover !important;
      background-position: 0% 50% !important;
      position: absolute;
      left: 0;
    }
    .slide_text {
      p {
        &.post_text {
          @apply text-base leading-tight;
        }
      }
    }
    &.post {
      @apply flex-row text-center px-10 border;
      height: auto;
      background: #fff !important;
      color: black !important;
      text-shadow: none !important;
      .slide_text {
      @apply px-6;
            h3 {
        @apply text-2xl px-10 leading-tight;
      }
        .post_text {
          @apply pt-4 text-lg leading-tight;
        }
      }
      &.image {
        @apply text-left p-0;
        padding-left: 330px;
        h3 {
          @apply text-xl pb-4 px-0 mb-4 leading-tight;
        }
        .slide_text {
          @apply p-8;
          .post_text {
            @apply px-0 pt-4 text-base leading-tight;
          }
        }
      }
    }
    &.link {
    &:hover {
    cursor: pointer;
  }
  }
    h3 {
      @apply text-3xl border-b pb-4 w-full leading-tight;
    }
  }
}

.preview_table {
  @apply p-6;
}
</style>
