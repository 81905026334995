<template>
  <div>
    <div
      class="list_container"
      :style="$store.style"
      :class="{ active: active }"
      v-if="view.config.data && items"
      v-resize="onResize"
    >
      <ul class="items">
        <input @click="selected = 0" placeholder="search" v-model="search" />
        <li
          @click="select(index)"
          :class="{ active: selected == index }"
          v-for="(item, index) in filtered"
          :key="index"
        >
          <p class="font-bold mb-2">{{ item.title }}</p>
          <p v-if="item.author">{{ item.author }}</p>
          <p v-if="item.subtitle">{{ item.subtitle }}</p>
        </li>
      </ul>
      <div class="selected" :class="{ image: filtered[selected].image }">
        <div class="back" @click="active = false">Go back</div>
        <div
          class="selected_title"
          :class="{ link: filtered[selected].url }"
          :style="'background: url(' + filtered[selected].image + ')'"
          @click="openLink(filtered[selected].url)"
        >
          <h1>
            {{ filtered[selected].title }}
            <span v-if="filtered[selected].date"
              >({{ filtered[selected].date }})</span
            >
          </h1>
          <h2 v-if="filtered[selected].subtitle">
            {{ filtered[selected].subtitle }}
          </h2>
        </div>
        <div class="selected_text" v-html="filtered[selected].text"></div>
      </div>
    </div>
    <div
      class="list_container"
      :class="{ active: active }"
      v-if="view.config.tag && items"
      v-resize="onResize"
    >
      <ul class="items">
        <input @click="selected = 0" placeholder="search" v-model="search" />
        <li
          @click="select(index)"
          :class="{ active: selected == index }"
          v-for="(item, index) in filtered"
          :key="index"
        >
          <p class="font-bold mb-2">{{ item.title }}</p>
          <p>{{ getAuthor(item.author) }}</p>
        </li>
      </ul>
      <div class="selected" :class="{ image: filtered[selected].image }">
        <div class="back" @click="active = false">Go back</div>
        <div
          class="selected_title"
          :class="{ link: filtered[selected].url }"
          :style="'background: url(' + filtered[selected].image + ')'"
          @click="openLink(filtered[selected].url)"
        >
          <h1>{{ filtered[selected].title }}</h1>
          <div class="post_stats mb-2">
            {{ filtered[selected].date | formatDate }} - Posted by
            <a
              :href="
                'https://edgeryders.eu/u/' +
                  getUsername(filtered[selected].author)
              "
              >{{ getAuthor(filtered[selected].author) }}</a
            >
          </div>
        </div>
        <div
          class="selected_text"
          v-if="filtered[selected].text"
          v-html="filtered[selected].text"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "list",
  data() {
    return {
      items: null,
      users: null,
      selected: 0,
      active: false,
      keysToSearch: ["title", "subtitle"],
      search: "",
    };
  },
  props: ["view"],
  components: {},
  methods: {
    select(id) {
      this.selected = id;
      this.active = !this.active;
    },
    getData(id) {
      var self = this;
      fetch("https://edgeryders.eu/raw/" + id + ".json")
        .then((response) => {
          response.text().then(function(text) {
            self.items = self.parseJson(text);
          });
        })
        .catch((error) => console.error(error));
    },
    getUsername(userid) {
      return this.users.filter((x) => x.id == userid)[0].username;
    },
    getAuthor(userid) {
      return (
        this.users.filter((x) => x.id == userid)[0].name ||
        "@" + this.users.filter((x) => x.id == userid)[0].username
      );
    },
    createItem(item) {
      var doc = document.createElement("div");
      doc.innerHTML = item.excerpt;


      var obj = {
        title: item.title,
        config: item,
        image: item.image_url,
        date: item.created_at,
        text: doc.innerHTML,
        id: item.id,
        url: "https://edgeryders.eu/t/" + item.slug,
        author: item.posters[0].user_id,
        likes: item.like_count,
      };
      return obj;
    },
    getTopics(tag) {
      var self = this;
      fetch("https://edgeryders.eu/tag/" + tag + ".json")
        .then((response) => response.json())
        .then((data) => {
          var array = data.topic_list.topics.map((x) => self.createItem(x));
          self.items = array.sort((a, b) => b.date.localeCompare(a.date));
          self.users = data.users;
        });
    },
    parseTable(text){
      var html = window.md.render(text);
      const doc = new DOMParser().parseFromString(html, "text/xml");
      console.log(doc);
      var keys = Array.prototype.map.call(doc.querySelectorAll('th'), function(th){
      return th.innerHTML;
      });
      var data = Array.prototype.map.call(doc.querySelector('tbody').querySelectorAll('tr'), function(tr){
      var obj = new Object();
      Array.prototype.map.call(tr.querySelectorAll('td'), function(td, index){
      var key = keys[index];
      var div = document.createElement("div");
      div.innerHTML = td.textContent;
      if (key == 'title' || key == 'text') {
        obj[key] = td.innerHTML;
      } else {
        obj[key] = div.textContent;
      }

      });
      return obj;
      });

      return data;
    },
    parseJson(value) {
      var code = value.match(/```([^`]*)```/);
      console.log(code);
      if (code) {
        var data = JSON.parse("{" + code[1] + "}").data;
        console.log(data);
        return JSON.parse("{" + code[1] + "}").data;
      } else {
        return this.parseTable(value);
      }
    },
    onResize({ width }) {
      if (width < 500) {
        this.viewport = "mobile";
        this.card_count = this.items.length;
        this.parallax = false;
      }
    },
  },
  mounted() {
    var width = window.innerWidth;
    if (width < 600) {
      this.viewport = "mobile";
    }
    if (this.view.config.data) {
      this.getData(this.view.config.data);
    }
    if (this.view.config.tag) {
      this.keysToSearch = ["title"];
      this.getTopics(this.view.config.tag);
    }
  },
  computed: {
    filtered() {
      return this.items.filter((item) =>
        this.keysToSearch.some((key) =>
          item[key].toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },
  filters: {
    formatDate: function(value) {
      return moment(String(value)).format("DD/MM/YY");
    },
  },
};
</script>

<style lang="scss">
.list_container {
  @apply border relative mb-8 text-base overflow-hidden flex;
  max-height: 400px;
  border-radius: 2px;
  min-height: 400px;
  background: white;
  width: 100%;
  margin-left: 0%;
  ul {
    @apply border-r w-full;
    width: 340px;
    flex-shrink: 0;
    padding: 0px 0 0 0 !important;
    margin: 0 !important;

    overflow-y: scroll;
    input {
      @apply border-b w-full p-4 py-3 text-base font-normal;
      top: 0;
      border-radius: 0;
      &:focus {
         outline: none;
      }
    }
    li {
      list-style-type: none;
      padding: 0;
      margin: 0 !important;
      font-size: 14px !important;
      border-left: 3px solid transparent;
      @apply leading-tight px-4 py-3 text-base w-full border-b inline-block;
      &.active {
        background: #efefef;
        border-left: 3px solid var(--color);
      }

      &:nth-child(odd) {
        background: #fafafa;
      }
      &:hover {
        cursor: pointer;
        background: #efefef;
      }
      &:last-child {
        border: none;
      }
      h1 {
        @apply leading-tight;
      }
      p {
        @apply leading-tight;
        font-size: 14px;
        margin: 0 !important;
      }
    }
  }
  .selected {
    overflow-y: scroll;
    .back {
      display: none;
    }
    .selected_title {
      width: 100%;
      @apply relative p-10 px-8 pb-0 mb-0 flex flex-col justify-center;
      color: black;
      &.link {
        &:hover {
          cursor: pointer;
          h1 {
            @apply underline;
          }
        }
      }

      h1 {
        @apply text-xl border-b w-full pb-2 mb-2;
      }
    }
    .selected_text {
      @apply px-8 py-2;
    }
    &.image {
      .selected_title {
        height: 200px;
        @apply justify-end pl-8 pr-20 pb-2 mb-2;
        background-size: cover !important;
        background-position: 50% 50% !important;
        text-shadow: 0 0px 20px rgba(0, 0, 0, 1);

        h2 {
          position: relative;
          z-index: 99;
          color: white;
        }
        .post_stats {
          color: white;
          position: relative;
          z-index: 99;
        }
        h1 {
          border-color: rgba(255, 255, 255, 0.3);
          position: relative;
          z-index: 99;
          color: white;
        }

        &:after {
          position: absolute;
          content: "";
          background: linear-gradient(30deg, black 0%, rgba(0, 0, 0, 0) 120%);
          height: 100%;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
      .selected_text {
        @apply py-4;
      }
    }
    p {
      @apply px-10 text-base mb-0;
    }

    h2 {
      color: rgba(0, 0, 0, 0.8);
      font-weight: normal;
    }
  }
}

@media only screen and (max-width: 600px) {
  .list {
    width: 90%;
    max-height: 800px;
    @apply mx-auto relative;
    .list_container {
      display: block;
      @apply relative;
      overflow-y: visible;
      .items {
        width: 100%;
        transition: transform 0.4s ease;
        height: 400px;
        border: none;
        overflow-y: scroll;
      }
    }
  }
  .list_container {
    .selected {
      @apply rounded-lg absolute;
      padding-top: 70px;
      background: white;
      height: 100%;
      right: -100%;
      transform: translateX(0%);
      transition: transform 0.4s ease;
      top: 0;
      width: 100%;
      .back {
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        @apply absolute font-bold text-lg border-b w-full mb-4 py-3 px-6 bg-gray-100;
      }
    }
    &.active {
      .items {
        transform: translateX(-100%);
      }
      .selected {
        transform: translateX(-100%);
        flex-shrink: 0;
      }
    }
  }
}
</style>
