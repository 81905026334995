<template>
  <div id="content" :style="$store.style">
    <div
      v-for="(view, index) in $store.views"
      :key="index"
      :ref="'el' + index"
      class="block"
      :class="view.type + '_block'"
    >
      <div v-if="view.type == 'title'" :id="getAnchor(view.content)">
        <h1 v-if="view.level == 'H1'">{{ view.content }}</h1>
        <h2 v-if="view.level == 'H2'">{{ view.content }}</h2>
        <h3 v-if="view.level == 'H3'">{{ view.content }}</h3>
      </div>
      <div
        v-if="view.type == 'text' && !Array.isArray(view.content)"
        v-html="view.content"
      ></div>
      <div v-if="view.type == 'row'" class="row_view">
        <div
          v-for="(item, index) in view.content"
          :style="'width: ' + view.width[index] + '%'"
          :key="index"
          v-html="item"
        ></div>
      </div>
      <div v-if="view.type == 'image'"><img :src="view.image" /></div>
      <div v-if="view.type == 'code'">
        <div v-html="view.content"></div>
      </div>
      <div v-if="view.type == 'video' && $store.style" class="video">
        <vue-plyr v-if="view.vimeo" :style="$store.style">
          <div
            data-plyr-provider="vimeo"
            :data-plyr-embed-id="view.vimeo"
          ></div>
        </vue-plyr>
        <vue-plyr v-if="view.youtube" :style="$store.style">
          <div
            data-plyr-provider="youtube"
            :data-plyr-embed-id="view.youtube"
          ></div>
        </vue-plyr>
      </div>
      <div v-if="view.type == 'cards'" class="cards">
        <cards :view="view" :items="view.content" />
      </div>
      <div v-if="view.type == 'list'" class="list">
        <list :view="view" />
      </div>
      <preview v-if="view.type == 'preview'" :config="view" />
      <masonry
        :cols="3"
        v-if="view.type == 'gallery'"
        class="gallery"
        :gutter="20"
      >
        <img
          v-preview:scope-a
          v-for="(item, index) in view.content"
          v-lazy="item.url"
          :alt="item.caption"
          :key="index"
        />
      </masonry>

      <div
        class="py-4 box"
        v-if="view.type == 'grid' && Array.isArray(view.content)"
        style="width: 100%; columns: 3"
      >
        <a
          style="display: flex; width: 97%; break-inside: avoid"
          class="w-full items-center flex-col mx-auto justify-center border text-center mb-8 pb-6 border-b border-t-4"
          v-for="(item, index) in view.content"
          :href="item.url"
          target="_blank"
          :key="index"
        >
          <img
            style="max-width: 60%"
            class="mb-3 mt-3"
            :src="item.icon"
            v-if="item.icon"
          />
          <img
            style="width: 100%; height: 180px; object-fit: cover"
            class="mb-3"
            :src="item.image"
            v-if="item.image"
          />
          <h3 class="font-bold border-b mt-2 mb-3 px-4 pb-2">
            {{ item.title }}
          </h3>
          <p class="px-4">{{ item.text }}</p>
        </a>
      </div>

      <div class="contact" v-if="view.type == 'contact'" style="width: 100%">
        <a
          class="contact_box"
          :class="key"
          v-for="(value, key, index) in view.config"
          :key="index"
          :href="value"
          target="_blank"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI from "photoswipe/dist/photoswipe-ui-default";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import createPreviewDirective from "vue-photoswipe-directive";
import cards from "@/elements/cards.vue";
import list from "@/elements/list.vue";
import preview from "@/elements/preview.vue";

export default {
  name: "Content",
  props: {
    views: Array,
  },
  components: {
    cards,
    list,
    preview,
  },
  directives: {
    preview: createPreviewDirective(null, PhotoSwipe, PhotoSwipeUI),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.plyr--full-ui input[type="range"] {
  color: var(--color) !important;
}

.plyr__control--overlaid {
  background: var(--color) !important;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: var(--color) !important;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(var(--color), 0.5);
}

.plyr__menu__container
  .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
  background: var(--color) !important;
}

#content {
  @apply pb-6;
  h2,
  h1 {
    @apply text-2xl tracking-tight pb-3 mt-8 mb-4 font-bold border-b;
  }
  h3 {
    @apply text-xl pb-2 mb-2 font-bold;
  }
}

.video {
  width: 70%;
  @apply my-6 mb-10;
  border-radius: 5px;
  .plyr--video,
  .plyr__video-wrapper {
    background: white;
    border-radius: 3px;
    overflow: hidden;
  }
}

#event .video {
  width: 100% !important;
}

.image_block {
  @apply mb-4;
}

.text_block {
  img.emoji {
    display: inline;
    height: 20px;
    width: auto;
  }
  @apply mb-4 text-lg;
  a {
    @apply underline;
  }
  ol {
    list-style-type: decimal;
    @apply ml-0 pl-6;
    width: 80%;
    max-width: 800px;
    li {
      @apply mb-1 pb-4;
    }
  }
  p br {
    content: "A" !important;
    display: block !important;
    margin-top: 1.5em !important;
  }
  ul {
    @apply pl-4;
    width: 80%;
    max-width: 800px;
    li {
      list-style-type: disc;
      @apply mb-1;
    }
  }
}

.cards_block {
  width: 140% !important;
  margin-left: -20%;
  @apply flex items-center justify-center;
}

table {
  background-color: #eeeeee;
  width: 100%;
  text-align: left;
  @apply leading-normal;
  border-collapse: collapse;
}
table td,
table th {
  border: 0px solid #aaaaaa;
  padding: 8px 10px;
}
table tbody td {
  font-size: 13px;
}
table tr:nth-child(even) {
  background: #ffffff;
}
table thead {
  background: #ffffff;
}
table thead th {
  font-size: 15px;
  font-weight: bold;
  color: #3f3f3f;
  border-left: 1px solid #efefef;
}
table thead th:first-child {
  border-left: none;
}

table tfoot td {
  font-size: 14px;
}

.row_view {
  @apply flex mb-6;
  div {
    width: 100%;
    @apply text-lg mr-8;
    &:last-child {
      margin-right: 0;
    }
    p {
      @apply mb-4;
    }
    a {
      @apply font-bold underline;
    }
    img {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  #content {
    .text_block {
      @apply mb-3;
    }
    h2 {
      @apply text-lg pb-2 mb-2 border-b;
    }
  }
  .row_view {
    @apply flex-col;
    div {
      width: 100% !important;
    }
  }
  .box {
    columns: 1 !important;
  }
}
</style>
